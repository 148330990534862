import {
  FetchProductsResponse, FetchProductsParams
} from "@/types/models/services/products";
import API from "./api";

const ENDPOINT = "v1"

const fetchAvailableProducts = ({
  power,
  accessTariff,
  selfcons,
  cups,
  promotionalCode,
}: FetchProductsParams,
  config: Parameters<typeof API.get>[2]
) => {
  return new Promise<FetchProductsResponse>(async (resolve, reject) => {
    API.get<FetchProductsResponse>(
      `${ENDPOINT}/pricelist`,
      [
        '?power', '=', power,
        '&atr', '=', accessTariff,
        '&cups', '=', cups,
        selfcons ? `&selfcons=${selfcons}` : '',
        promotionalCode ? `&promocode=${promotionalCode}` : '',
      ].join(''),
      config
    )
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err);
      })
  })
}

const fetchAvailableGasProducts = ({
  accessTariff,
  cups,
}: Pick<FetchProductsParams, "accessTariff" | "cups">,
  config: Parameters<typeof API.get>[2]
) => {
  return new Promise<FetchProductsResponse>(async (resolve, reject) => {
    API.get<FetchProductsResponse>(
      `v2/gas/pricelist`,
      [
        // '?power', '=', power,
        '?atr', '=', accessTariff,
        '&cups', '=', cups,
      ].join(''),
      config
    )
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err);
      })
  })
}

export default {
  fetchAvailableProducts,
  fetchAvailableGasProducts
};


