import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@gisce/oficina-virtual-components";
import { 
  Box, Typography, Grid, Paper, Divider 
} from "@mui/material"
import { useForm, Controller } from "react-hook-form"
import { z } from "zod";
import { zodResolver } from '@hookform/resolvers/zod';
import Settings from "../../settings";
import Address from "../Address";
import AccessTariffSelectField from "../form-fields/AccessTariffSelectField";
import CUPSField, { cupsValid } from "../form-fields/CUPSField";
import ComerOriginSelectField from "../form-fields/ComerOriginSelectField";
import FileUploadField, { validateFileUpload } from "../form-fields/FileUploadField";
import RadioGroupField from "../form-fields/RadioGroupField";
import { useTranslation } from "react-i18next";
import { RootState } from "@/store";
import { FormControlLabel } from "@mui/material";
import { Checkbox } from "@mui/material";
import { FormHelperText } from "@mui/material";
import GasProductSelectField from "./components/GasProductSelectField";
import { Product } from "@/types/models/services/products";
import { default as productsService } from "@/services/products";
import useI18n from "@/hooks/useI18n";
import { default as cupsService } from "./services/cups";
import { TextField } from "@mui/material";
import { submitAboutGasContractData } from "@/actions/electricityContractation";

type StepAboutGasContractFormData = {
  includeGas: boolean;
  cups: string;
  changeOwner: string;
  accessTariff: number;
  selectedProduct: number;
  selectedProductName: string;
  invoice: {
    name: string;
    content: string;
  };
}

interface IFormAboutGasContract {
  onGoBack: () => void;
  onSubmit: (values: StepAboutGasContractFormData) => void;
}

const FormAboutGasContract: FC<IFormAboutGasContract> = ({
  onGoBack,
  onSubmit
}) => {
  const { t } = useTranslation();
  const { currentLang } = useI18n();
  const dispatch = useDispatch();

  const elecContractration = useSelector((state: RootState) => state.electricityContractation);
  const gasContractration = useSelector((state: RootState) => state.electricityContractation.gas);

  const [availableProducts, setAvailableProducts] = useState<Product[]>([]);
  const [isFetchingProducts, setIsFetchingProducts] = useState(false);
  const [comerOriginEnabled, setComerOriginEnabled] = useState(false);

  const schema = z.object({
    includeGas: z.boolean().optional(),
    cups: z.string().min(1, t('common:text.required_field'))
    .superRefine((cups, ctx) => {
      if (!cupsValid(cups)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('common:text.contractation_cups_not_valid')
        });
      } 
      // else {
        // cupsService.validateGasCUPSAvailability(cups)
        // .then((data) => {
        //   debugger
        // })
        // .catch((err) => {
          // {message: t('common:text.contractation_cups_not_available')}

        // });
      // }
    }),
    accessTariff: z.coerce.number({
      invalid_type_error: t('common:text.required_field')
    }).min(1, {message: t('common:text.required_field')}),
    selectedProduct: z.coerce.number({
      invalid_type_error: t('common:text.required_field')
    }).positive({message: t('common:text.required_field')}),
    // selectedProductName: z.string(),

    // // TODO: Use the type File together with the new MultiFileUpload or
    // // import { MuiFileInput } from 'mui-file-input'
    // invoice: z.object({
    //   name: z.string(),
    //   content: z.string().regex(/^data:.*\/[a-zA-Z]+;base64,/, "Invalid base64 format"),
    // })
    // .refine((file) => {
    //   return atob(file.content.substring(file.content.indexOf(',') + 1)).length < 15000000;
    // }, 'File size must be less than 15MB')
    // .array(),
  })

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    register,
    formState: { errors, isSubmitting }
  } = useForm<StepAboutGasContractFormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      includeGas: (Settings.contractation.dual.isGasMandatory ?? false) ?
        true : gasContractration.includeGas,
      cups: gasContractration.cups,
      changeOwner: gasContractration.changeOwner,
      accessTariff: gasContractration.accessTariff,
      selectedProduct: gasContractration.selectedProduct,
      selectedProductName: gasContractration.selectedProductName,
      invoice: gasContractration.invoice,
    }
  });

  const watchedIncludeGas = watch("includeGas");
  const watchedAccessTariff = watch("accessTariff");
  const watchedSelectedProduct = watch("selectedProduct");
  const watchedCUPS = watch("cups");

  const onAboutGasContractSubmit = async (formValues: StepAboutGasContractFormData) => {
    const values = Object.assign({}, gasContractration, formValues)
    await onSubmit(values)
  };

  const fetchAvailableProducts = async () => {
    setIsFetchingProducts(true);
    productsService.fetchAvailableGasProducts({
      accessTariff: watchedAccessTariff,
      cups: watchedCUPS,
    }, {params: {lang: currentLang.value}}
    ).then(res => {
      setAvailableProducts(res);
      const currentSelectedProductId = res.find(
        prod => prod.id === gasContractration.selectedProduct
      )?.id;
      if(currentSelectedProductId) {
        setValue("selectedProduct", currentSelectedProductId);
      }
    }).finally(() => {
      setIsFetchingProducts(false);
    })
  };

  useEffect(() => {
    if(watchedAccessTariff) {
      fetchAvailableProducts();
    }
  }, [watchedAccessTariff]);


  useEffect(() => {
    if(!availableProducts.find(prod => prod.id === watchedSelectedProduct)) {
      setValue("selectedProduct", -1);
    }
  }, [availableProducts]);

  useEffect(() => {
    if ( watchedSelectedProduct ) {
      const selectedProductName = availableProducts.find(prod => prod.id === watchedSelectedProduct)?.name;
      if (selectedProductName) {
        setValue("selectedProductName", selectedProductName);
      }
    }
  }, [watchedSelectedProduct]);

  // Debounce data
  useEffect(() => {
    const debounceForm = setTimeout(() => {
      const newValues = watch();
      for (const element in newValues) {
        if (gasContractration[element] !== newValues[element as keyof StepAboutGasContractFormData]) {
          console.debug("differs where ", gasContractration[element], " !== ", newValues[element as keyof StepAboutGasContractFormData] );
          dispatch(submitAboutGasContractData(newValues))
          return
        }
      }
    }, 2000);

    return () => clearTimeout(debounceForm);
  }, [...Object.values(watch())]);

  console.debug("errors", errors);

  return (
    <div>
      <form onSubmit={handleSubmit(onAboutGasContractSubmit)}>
        <Paper variant="outlined" sx={{pl: 2, mb: 2}}>
          <Controller
            name={"includeGas"}
            control={control}
            render={({ field }) => <FormControlLabel
              control={<Checkbox
                {...field}
                checked={field.value}
                />} 
              label={
                <Typography variant="h6" color="primary">
                  {t('common:text.contractation_include_gas')}
                </Typography>
              } 
            />}
          />
          {errors.includeGas?.message &&
            <FormHelperText error={true}>
              {errors.includeGas.message}
            </FormHelperText>
          }

        </Paper>
        {watchedIncludeGas && (
          <>
            <Typography variant="h6" color="primary">
              {t('common:text.contractation_current_gas_contract_data')}
            </Typography>
            <Typography variant="body2" sx={{mb: 2}}>
              {t('common:text.contractacion_about_installation_info')}
            </Typography>
            <Box sx={{ml: {md: 5}, mr: {md: 5}}}>
              <Grid item xs={12}>
                <Typography variant="h6">
                  {t('common:text.contractation_cups_title')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8} md={4}>

                <TextField
                  label={t('common:text.contractation_cups_gas')}
                  {...register("cups")}
                  error={!!(errors.cups)}
                  style={{ width: "100%" }}
                />
                {!!(errors.cups?.message) &&
                  <FormHelperText error={true}>
                    {errors.cups.message}
                  </FormHelperText>
                }

              </Grid>
              <Grid item xs={12}>
                <div className="helper-text">{t('common:text.contractation_cups_gas_helper')}</div>
                <Divider style={{marginBottom: 10, marginTop: 10}}/>
              </Grid>
              {!Object.keys(elecContractration.address) && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      {t('common:text.contracts_direction')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Address readonly value={elecContractration.address} />
                  </Grid>
                </>
              )}
              <Grid item xs={12} sx={{mt: 1, mb: 1}}>
                <Paper elevation={3} sx={{padding: 2}}>
                  <Typography variant="h6">
                    { (Settings.contractation.nameInChangeOwnerQuestion ?? false) ?
                      t("common:text.contractation_change_owner_question_named", 
                        {owner: gasContractration.name + " " + 
                          gasContractration.surName1 + " " + 
                          gasContractration.surName2
                        })
                      : t("common:text.contractation_change_owner_question")
                    }
                  </Typography>

                  <Controller
                    name={"changeOwner"}
                    control={control}
                    render={({ field }) => <RadioGroupField
                      label={""}
                      radioElements={
                        [
                          {
                            label: t("common:text.generic_yes"), 
                            val: "changeOwner", style: 
                            {width: "25%"}
                          },
                          {
                            label: t("common:text.generic_no"), 
                            val: "dontChangeOwner", 
                            style: {width: "25%"}
                          }
                        ]
                      }
                      input={field}
                      meta={{
                        touched: !!errors.accessTariff?.message,
                        error: errors.accessTariff?.message,
                      }}
                      row
                    />}
                  />
                </Paper>
                <Divider sx={{mb: 1, mt: 1}}/>
              </Grid>

              {(!gasContractration.sipsData || 
                !watchedAccessTariff) && 
                watchedCUPS &&
                <>
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      {t('common:text.contract_title')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8} md={6} mb={2}>

                    <Controller
                      name={"accessTariff"}
                      control={control}
                      render={({ field }) => <AccessTariffSelectField
                        gas={true}
                        initialTariff={watchedAccessTariff}
                        label={t('common:text.contractation_access_tariff')}
                        meta={{
                          touched: !!errors.accessTariff?.message,
                          error: errors.accessTariff?.message,
                        }}
                        input={field}
                      />}
                    />

                  </Grid>
                </>
              }
              {/* TODO {comerOriginEnabled &&
                <Grid item xs={12} sm={8} md={6} mb={2}>
                  <Field
                    name="comerOrigin"
                    component={ComerOriginSelectField}
                    onAvailableComersUpdate={handleAvailableComersUpdate}
                    onUpdate={handleComerOriginUpdate}
                    gas={true}
                  />
                </Grid>
              } */}
              <Grid item xs={12} mb={3}>
                <Divider sx={{mb: 2, mt: 2}}/>
                <Typography variant="h6">
                  {t('common:text.contractation_last_gas_invoice')}
                </Typography>
                <Paper sx={{p: 2}} variant="outlined">
                  <Controller
                    name={"invoice"}
                    control={control}
                    render={({ field }) => <FileUploadField
                      min={1}
                      max={3}
                      label={t('common:text.contractation_selfcons_document')}
                      hint={t('common:text.contractation_selfcons_helper')}
                      anotherLabel={t('common:text.contractation_selfcons_add')}
                      removeLabel={t('common:text.remove')}
                      meta={{
                        touched: !!errors.invoice,
                        error: errors.invoice?.message,
                      }}
                      input={field}
                    />}
                  />
                </Paper>
              </Grid>
            </Box>

            <Typography variant="h6" color="primary">
              {t('common:text.contractation_new_pricelist')}
            </Typography>
            <Typography variant="body2" sx={{mb: 2}}>
              {t('common:text.contractation_current_pressure_warning')}
            </Typography>
            <Box sx={{ml:{md: 5}, mr:{md: 5}}}>
              {watchedAccessTariff &&
                <>
                  <Controller
                  name={"selectedProduct"}
                  control={control}
                  render={({ field }) => <GasProductSelectField
                    availableProducts={availableProducts}
                    loading={isFetchingProducts}
                    input={field}
                  />}
                  />
                  {!!(errors.selectedProduct) &&
                  <FormHelperText error={true}>
                    {errors.selectedProduct.message}
                  </FormHelperText>
                  }
                </>
              }
            </Box>
          </>
        )}

        <Divider sx={{mt: 3, mb: 3}}/>
        <Button
          variant={'text'}
          onClick={onGoBack}
          style={{ marginRight: 12 }}
          disabled={isSubmitting}
        >
          {t('common:text.contractation_previous')}
        </Button>
        <Button
          type="submit"
          color={'primary'}
          variant={'contained'}
          loading={isSubmitting}
          disabled={!!Object.keys(errors).length}
        >
          {t('common:text.contractation_next')}
        </Button>

      </form>
    </div>
  );
};

export default FormAboutGasContract;
